/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import useStyle from './styles';
import useAuth from 'hooks/useAuth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import palpiteLogo from 'assets/logo_palpite - Copia.png';
import palpiteLogoSmall from 'assets/logo.png';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
// import MailIcon from '@material-ui/icons/Mail';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import { deposit, home } from 'pages/Wallet/routes';
import { Link } from 'react-router-dom';
import { toMoney } from 'helpers/format';
import ListItem from '@material-ui/core/ListItem';
import ButtonMui from '@material-ui/core/Button';
import liveBingoIcon from '../../assets/icons/liveBingo.svg';
import videoBingoIcon from '../../assets/icons/videoBingo.svg';
import cassinoIcon from '../../assets/icons/cassino.svg';
import loteriaIcon from '../../assets/icons/loteriaPopular.svg';
import sports from '../../assets/icons/sports.svg';
import sportsLive from '../../assets/icons/sports-live.svg';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { ArrowPathIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useScroll } from 'hooks/useScroll';
import clsx from 'clsx';
import { Button as TwButton } from 'components/Tailwind/button/button';
import { ModalDeposit } from 'components/Wallet/DepositForm/depositComponents/modalDeposit';
// import { LanguageMenu } from '../languageMenu/languageMenu';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { ModalSignup } from 'pages/SignUp/modalSignup';

const Storage = window.localStorage;

export let MenuItems = [
  // { label: 'Home', link: '/', icon: liveBingoIcon },
  // {
  //   label: 'Esportes',
  //   link: '/esportes',
  //   icon: sports,
  //   translate: 'menu.sports',
  // },
  // { label: 'Ao Vivo', link: '/esportes-ao-vivo', icon: sportsLive },
  // old bingo
  // {
  //   label: 'Live Bingo',
  //   link: '/bingo-ao-vivo',
  //   badge: true,
  //   // icon: liveBingoIcon,
  // },
  {
    label: 'Bingo ao vivo',
    // link: '/live-bingo-bg',
    link: '/cassinoGames/Banana Bingo/bananaGames/2/6',
    badge: true,
    // icon: liveBingoIcon,
  },
  {
    label: 'Video Bingo',
    // link: '/live-bingo-bg',
    link: '/cassino/Video Bingo',
    // icon: liveBingoIcon,
  },
  // { label: 'Vídeo Bingo', link: '/video-bingo?filter=videoBingo', icon: videoBingoIcon },
  {
    label: 'Cassino',
    link: '/cassino',
    // icon: cassinoIcon,
    translate: 'menu.casino',
  },
  {
    label: 'Cassino ao vivo',
    link: '/cassino/Cassino ao Vivo',
    // icon: cassinoIcon,
    translate: 'menu.liveCasino',
  },
  // {
  //   label: 'Loteria Popular',
  //   link: '/loteria-popular',
  //   icon: loteriaIcon,
  //   translate: 'menu.popularLottery',
  // },
];

// eslint-disable-next-line no-unused-vars
const Header = ({ onClick }) => {
  const router = useLocation();
  const style = useStyle();
  const { currentUser, depositModal, setDepositModal } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [walletAmount, setWalletAmount] = useState(currentUser?.Wallet?.amount);
  const location = useLocation();
  const { media, orientation } = useDeviceInfo(true);

  const isMobile = useMediaQuery('(max-width:1019px)');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (currentUser?.roleName == 'SELLER') {
      setWalletAmount(currentUser?.Wallet?.amountToSell);
    } else {
      setWalletAmount(
        currentUser?.Wallet?.amountAward +
          currentUser?.Wallet?.amountDeposit +
          currentUser?.Wallet?.amountBonusSports +
          (currentUser?.Wallet?.amountBonusEveryMatrix ?? 0)
      );
    }
  }, [currentUser?.Wallet, currentUser?.roleName]);

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  });

  const history = useHistory();
  const pathname = history.location.pathname;
  // const { scrollDirection, scrollY } = useScroll();

  useEffect(() => {
    const timeoutFirstLogin = setTimeout(() => {
      if (!currentUser) return;
      if (
        !depositModal &&
        walletAmount + currentUser?.Wallet?.amountBonusBingo <= 0
      ) {
        const openModalEvent = new window.Event('openModalDeposit');
        document.dispatchEvent(openModalEvent);
        setDepositModal(true);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutFirstLogin);
    };
  }, [currentUser, depositModal, walletAmount]);

  return (
    <AppBar
      color='transparent'
      elevation={0}
      id='header'
      className={clsx(
        style.menu,
        style.menuActive
        // !isMobile
        //   ? style.menuActive
        //   : media === 'mobile' &&
        //     orientation === 'horizontal' &&
        //     (location.pathname === '/caletaGames' ||
        //       location.pathname === '/every-matrix' ||
        //       location.pathname === '/vibraGames')
        //   ? style.menuHidden
        //   : scrollDirection === 'up' || scrollY < 50
        //   ? style.menuActive
        //   : style.menuHidden
      )}
    >
      <Container className={'no-space'}>
        <Toolbar
          disableGutters
          className={`${style.toolbar} !tw-h-fit !tw-min-h-fit tw-py-2`}
        >
          <>
            {pathname.includes('/vibraGames') ||
            pathname.includes('/caletaGames') ||
            pathname.includes('/every-matrix') ||
            pathname.includes('/cassinoGames') ? (
              <div
                className={`tw-w-full tw-flex tw-justify-between tw-items-center tw-px-2 ${
                  isMobile ? 'tw-h-6' : 'tw-h-10'
                }`}
              >
                <button
                  type='button'
                  className='focus:tw-outline-none'
                  onClick={() => {
                    if (pathname.includes('/cassinoGames')) {
                      history.goBack();
                      history.goBack();
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <ChevronLeftIcon className='tw-w-6 tw-h-6 tw-text-primary' />
                </button>
                <RouterLink to='/'>
                  <img
                    src={palpiteLogo}
                    className='tw-w-auto tw-h-8 md:tw-h-12'
                    alt='PNR'
                  />
                </RouterLink>
                <ModalDeposit
                  orientationMobile={
                    media === 'mobile' && orientation === 'horizontal'
                  }
                  mediaScreen={media}
                  shape='rounded'
                  withTag
                />
              </div>
            ) : (
              <>
                {isMobile ? (
                  // <MobileMenu />
                  <RouterLink to='/'>
                    <img
                      src={palpiteLogo}
                      className='tw-w-auto tw-h-10 md:tw-h-12'
                      alt='PNR'
                    />
                  </RouterLink>
                ) : (
                  <RouterLink to={'/'}>
                    <img
                      src={palpiteLogo}
                      className={`tw-h-12 md:tw-h-12`}
                      alt='PNR'
                    />
                  </RouterLink>
                )}
                {!isMobile && (
                  <div className={style.links}>
                    {MenuItems.map(m => {
                      if (
                        (currentUser?.partnerId == 11) &
                        (m.label == 'Loteria Popular')
                      ) {
                        return null;
                      }
                      return (
                        <RouterLink
                          to={m.link}
                          className={
                            router?.pathname === m.link
                              ? 'tw-border-b-4 tw-border-solid tw-border-b-primary tw-border-t-transparent tw-border-x-transparent'
                              : ''
                          }
                          key={m.label}
                          exact={m.link === '/'}
                        >
                          {m.badge ? (
                            <WithBadge style={{ marginLeft: 12 }}>
                              {m.label}
                            </WithBadge>
                          ) : (
                            <span>
                              {m.translate ? t(m.translate) : m.label}
                            </span>
                          )}
                        </RouterLink>
                      );
                    })}
                  </div>
                )}

                <div>
                  <Grid
                    container
                    spacing={2}
                    className={style.actions}
                    alignItems={'flex-end'}
                  >
                    {!currentUser && (
                      <div className='tw-flex tw-flex-row tw-gap-1.5 tw-items-center md:tw-gap-4 tw-justify-center'>
                        <ModalSignup isMobile={isMobile} />
                        <Button
                          to={{
                            pathname: '/entrar',
                            state: {
                              from: {
                                pathname: history?.location?.pathname,
                                search: history?.location?.search,
                              },
                            },
                          }}
                          component={RouterLink}
                          variant='outlined'
                          size='small'
                        >
                          <span>{t('appBar.signin')}</span>
                        </Button>
                      </div>
                    )}
                    {currentUser && (
                      <div className='tw-flex tw-gap-1 md:tw-gap-3 tw-items-center'>
                        <ModalDeposit
                          orientationMobile={
                            media === 'mobile' && orientation === 'horizontal'
                          }
                          mediaScreen={media}
                          shape='rounded'
                          withTag
                        />
                        <Popover>
                          {({ open }) => (
                            <>
                              <Popover.Button
                                className={`tw-py-1 tw-px-2.5 ${
                                  media === 'mobile' &&
                                  orientation === 'horizontal'
                                    ? 'md:tw-py-1 md:tw-px-1.5 md:tw-text-xs'
                                    : 'md:tw-py-1.5 md:tw-px-5 md:tw-text-base'
                                } tw-rounded-full tw-border tw-border-solid tw-border-primary focus:tw-outline-none tw-text-xs tw-bg-transparent tw-flex tw-gap-1 md:tw-gap-2 tw-items-center tw-text-primary`}
                                ref={setReferenceElement}
                              >
                                <ArrowPathIcon className='tw-w-3 tw-h-3 md:tw-w-4 md:tw-h-4' />
                                <span>{toMoney(walletAmount)}</span>
                              </Popover.Button>
                              <Popover.Panel
                                className='tw-bg-white tw-py-2 tw-px-5 tw-rounded-lg tw-z-10 tw-shadow'
                                ref={setPopperElement}
                                style={styles.popper}
                                {...attributes.popper}
                              >
                                <div className='tw-flex tw-flex-col tw-text-black'>
                                  <p className='tw-py-2 tw-border-b tw-border-gray-300'>
                                    {t('money.depositBalance')}{' '}
                                    {toMoney(
                                      currentUser?.Wallet?.amountDeposit
                                    )}
                                  </p>
                                  <p className='tw-py-2 tw-border-b tw-border-gray-300'>
                                    {t('money.sportsBonusBalance')}{' '}
                                    {toMoney(
                                      currentUser?.Wallet?.amountBonusSports
                                    )}
                                  </p>
                                  <p className='tw-py-2'>
                                    {t('money.casinoBonusBalance')}{' '}
                                    {toMoney(
                                      currentUser?.Wallet
                                        ?.amountBonusEveryMatrix
                                    )}
                                  </p>
                                  <p className='tw-py-2'>
                                    {t('money.withdrawBalance')}{' '}
                                    {toMoney(currentUser?.Wallet?.amountAward)}
                                  </p>
                                  <p className='tw-py-2'>
                                    {t('money.bingoBonusBalance')}{' '}
                                    {toMoney(
                                      currentUser?.Wallet?.amountBonusBingo
                                    )}
                                  </p>
                                </div>
                              </Popover.Panel>
                            </>
                          )}
                        </Popover>
                        <Grid item>
                          <Button
                            variant='outlined'
                            size='small'
                            style={{ minWidth: 'auto' }}
                            onClick={onClick}
                            className={`!tw-px-1 md:!tw-px-1.5 !tw-py-0.5 md:!tw-py-1 ${
                              media === 'mobile' && orientation === 'horizontal'
                                ? 'md:!tw-px-1.5'
                                : 'md:!tw-px-2'
                            }`}
                          >
                            <PersonIcon />
                          </Button>
                        </Grid>
                      </div>
                    )}
                  </Grid>
                </div>
              </>
            )}
          </>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const router = useLocation();
  const toggleDrawer = useCallback(
    to => () => setOpen(prev => to ?? !!prev),
    []
  );
  const style = useStyle();
  const { currentUser } = useAuth();

  return (
    <div className={style.mobileHeaderArea}>
      <IconButton
        color='inherit'
        aria-label='menu'
        onClick={toggleDrawer(true)}
        className='!tw-p-1.5'
      >
        <MenuIcon />
      </IconButton>
      <RouterLink to='/'>
        <img src={palpiteLogoSmall} className='tw-w-auto tw-h-10' alt='PNR' />
      </RouterLink>
      <SwipeableDrawer
        anchor='left'
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box>
          <IconButton
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={2}>
          <List>
            {MenuItems.map(menu => {
              if (
                (currentUser?.partnerId == 11) &
                (menu.label == 'Loteria Popular')
              ) {
                return null;
              }

              return (
                <ListItem button key={menu.label}>
                  <RouterLink
                    to={menu.link}
                    className={
                      router?.pathname === m.link
                        ? 'tw-border-b-4 tw-border-solid tw-border-b-primary tw-border-t-transparent tw-border-x-tw-border-t-transparent'
                        : ''
                    }
                    exact={menu.link === '/'}
                  >
                    {menu.badge ? (
                      <WithBadge mobile>
                        <ListItemText primary={menu.label} />
                      </WithBadge>
                    ) : (
                      <ListItemText primary={menu.label} />
                    )}
                  </RouterLink>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};

const WithBadge = ({ children, style, mobile = false }) => {
  const Component = mobile ? LiveBadgeMobile : LiveBadge;
  return (
    <span style={style}>
      <Component
        color='primary'
        badgeContent=''
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <>{children}</>
      </Component>
    </span>
  );
};

WithBadge.propTypes = {
  children: PropTypes.any,
  style: PropTypes.any,
  mobile: PropTypes.bool,
};

Header.propTypes = {
  onClick: PropTypes.func,
};

const LiveBadge = withStyles(theme => ({
  badge: {
    left: -theme.spacing(6),
    top: 9,
    height: 10,
    minWidth: 10,
    width: 10,
    padding: 0,
  },
}))(Badge);

const LiveBadgeMobile = withStyles(() => ({
  badge: {
    top: 15,
  },
}))(LiveBadge);

export default Header;
